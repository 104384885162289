import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    AspectState,
    AspectTypes,
    CommonFieldState,
    DateFieldState,
    ErrorType,
    FieldState,
    FieldStateReference,
    FieldTypes,
    FormState,
    GroupState,
    MultiSectionAspectState,
    MultiSectionRowState,
    NumberFieldState,
    SingleOptionFieldState,
    StateEnum,
    TextFieldState,
    WorkflowActionState
} from "./state";
import {Dispatch} from "react";
import {applyDataToFormState, loadDomain, loadExistingDomain, metaToFormState} from "../../api/domain-load-service";
import {submitDomain, triggerRules, triggerSectionRules, updateDomain} from "../../api/domain-save-service";
import * as uuid from 'uuid';
import {IMessage} from "../../components/SnackbarProvider";
import {applyRules} from "./inference-rules";
import {
    DomainData,
    DomainFieldValidation,
    DomainMeta,
    DomainValidation,
    ReferenceDataValue,
    UpdateDomain
} from "../../placeholder";
import {
    aspectsHaveErrors,
    atLeastOneAspectVisible,
    clearErrors,
    hasError,
    isEditable,
    isEmpty,
    isMandatory,
    isVisible
} from "./form-utils";
import {notUndefined} from "../../util/notUndefined";
import AlertTypes from "odl-components/components/Alert/Alert.types";
import {AxiosError} from "axios";
import {DomainWorkflowResult, executeWorkflowAction} from "../../api/workflow-service";
import {PortalConfig} from "../../api/contexts";

export const initialState: FormState = {
    changesPending: false,
    typeId: "",
    typeName: "",
    groups: [],
    aspects: [],
    rules: [],
    actions: [],
    validatedGroups: 0,
    customErrors: [],
    inInitialStatus: true,
    draftEnabled: false,
    trap: false,
    state: StateEnum.LOADING
};

export interface FieldChangeActionPayload {
    aspect: AspectState;
    row?: MultiSectionRowState;
    field: FieldState;
    value: any;
}

export interface FieldRefDataUpdateActionPayload {
    aspectName: string;
    rowKey?: string;
    fieldName: string;
    value: ReferenceDataValue[];
}

export interface RemoveRowActionPayload {
    aspect: AspectState;
    row: MultiSectionRowState;
}

export const formSlice = createSlice({
    name: "domainForm",
    initialState,
    reducers: {
        updateFieldReferenceData,
        updateFieldValue,
        startLoad(state) {
            state.state = StateEnum.LOADING;
        },
        endLoad(state) {
            state.state = StateEnum.INITIALIZED;
        },
        loadDomainForm,
        validateForm,
        addMultiSectionRow,
        removeMultiSectionRow,
        applySubmissionErrorsAction,
        applyTriggerData
    }
});

function updateFieldReferenceData(formState: FormState, action: PayloadAction<FieldRefDataUpdateActionPayload>) {
    const {aspectName, rowKey, fieldName, value} = action.payload;
    const aspectState = formState.aspects.find(a => a.name === aspectName);
    if (aspectState) {
        let fieldStates: FieldState[] | undefined;
        if (AspectTypes.SINGLE_SECTION === aspectState.type) {
            fieldStates = aspectState.fields;
        } else if (rowKey) {
            const rowState = aspectState.rows.find(r => r.key === rowKey);
            fieldStates = rowState?.fields;
        }
        const fieldState = fieldStates?.find(f => f.template.name === fieldName);
        if (fieldState && (
            fieldState.type === FieldTypes.DROPDOWN_SINGLE ||
            fieldState.type === FieldTypes.DROPDOWN_MULTI ||
            fieldState.type === FieldTypes.CHECKBOX_GROUP ||
            fieldState.type === FieldTypes.RADIO
        )) {
            fieldState.refDataType.options = value;
        }
    }
}

function updateFieldValue(formState: FormState, action: PayloadAction<FieldChangeActionPayload>) {
    const {aspect, row, field, value} = action.payload;

    formState.changesPending = true;

    const aspectState = formState.aspects.find(a => a.id === aspect.id);
    let rowState: MultiSectionRowState | undefined;
    let fieldState: FieldState | undefined;
    if (aspectState) {
        if (aspectState.type === AspectTypes.SINGLE_SECTION) {
            fieldState = aspectState.fields.find(f => f.template.id === field.template.id);
        } else if (aspectState.type === AspectTypes.MULTI_SECTION && row) {
            rowState = aspectState.rows.find(r => r.key === row.key);
            if (rowState) {
                fieldState = rowState.fields.find(f => f.template.id === field.template.id);
                rowState.customErrors = [];
            }
        }
        if (fieldState) {
            applyUpdates(formState, aspectState, rowState, fieldState, value);
        }
        aspectState.customErrors = [];
    }
}

function applyUpdates(formState: FormState,
                      aspectState: AspectState,
                      rowState: MultiSectionRowState | undefined,
                      fieldState: FieldState,
                      value: any) {

    updateField(fieldState, value, aspectState);
    runRules(formState, aspectState, fieldState);
    validateField(aspectState, fieldState);

    if (fieldState.type === FieldTypes.DROPDOWN_SINGLE) {
        if (fieldState.refDataType.parentCascadingField) {
            fieldState.refDataType.parentCode = value?.parentCode;
        }
        cascadeRefDataUp(formState, fieldState, rowState?.key);
        cascadeRefDataDown(formState, fieldState, aspectState, rowState);
    }
}

function cascadeRefDataUp(formState: FormState, updatedField: SingleOptionFieldState, rowKey?: string) {
    if (updatedField.refDataType.parentCascadingField) {
        const parent = findParent(formState.aspects, updatedField.refDataType.parentCascadingField, rowKey);
        if (parent) {
            const newValue = parent.field.refDataType.options.find(ref => ref.code === updatedField.refDataType.parentCode);
            if (parent.field.value !== newValue) {
                if (parent.field.refDataType.parentCascadingField) {
                    parent.field.refDataType.parentCode = newValue?.parentCode;
                }
                updateField(parent.field, newValue);
                runRules(formState, parent.aspect, parent.field);
                validateField(parent.aspect, parent.field);
                cascadeRefDataUp(formState, parent.field);
            }
        }
    }
}

function findParent(aspects: AspectState[], ref: FieldStateReference, rowKey?: string):
    { aspect: AspectState, field: SingleOptionFieldState } | undefined {
    for (let aspect of aspects) {
        if (aspect.type === AspectTypes.SINGLE_SECTION && ref.sectionTemplateId === aspect.template.id) {
            for (let field of aspect.fields) {
                if (field.type === FieldTypes.DROPDOWN_SINGLE && ref.fieldTemplateId === field.template.id) {
                    return {aspect, field};
                }
            }
        } else if (aspect.type === AspectTypes.MULTI_SECTION && ref.sectionTemplateId === aspect.template.id) {
            for (let row of aspect.rows) {
                if (row.key === rowKey) {
                    for (let field of row.fields) {
                        if (field.type === FieldTypes.DROPDOWN_SINGLE && ref.fieldTemplateId === field.template.id) {
                            return {aspect, field};
                        }
                    }
                }
            }
        }
    }
}

function cascadeRefDataDown(formState: FormState,
                            updatedField: SingleOptionFieldState,
                            updatedAspect: AspectState,
                            updatedRow: MultiSectionRowState | undefined) {

    function cascadeRefDataFieldsDown(fields: FieldState[], aspect: AspectState) {
        for (let field of fields) {
            if (field.type === FieldTypes.DROPDOWN_SINGLE || field.type === FieldTypes.DROPDOWN_MULTI || field.type === FieldTypes.CHECKBOX_GROUP) {
                if (field.refDataType.parentCascadingField?.fieldTemplateId === updatedField.template.id) {
                    if (updatedField.value) {
                        field.refDataType.parentCode = updatedField?.value?.code;
                        field.refDataType.options = [];
                        if (field.value) {
                            updateField(field, field.type === FieldTypes.DROPDOWN_MULTI || field.type === FieldTypes.CHECKBOX_GROUP ? [] : undefined);
                            runRules(formState, aspect, field);
                            validateField(aspect, field);
                            if (field.type === FieldTypes.DROPDOWN_SINGLE) {
                                cascadeRefDataDown(formState, field, aspect, updatedRow);
                            }
                        }
                    } else {
                        field.refDataType.parentCode = undefined;
                        field.refDataType.options = [];
                    }
                }
            }
        }
    }

    if (updatedRow) {
        cascadeRefDataFieldsDown(updatedRow.fields, updatedAspect);
    } else {
        for (let aspect of formState.aspects) {
            if (aspect.type === AspectTypes.SINGLE_SECTION) {
                cascadeRefDataFieldsDown(aspect.fields, aspect);
            } else if (aspect.type === AspectTypes.MULTI_SECTION) {
                cascadeRefDataFieldsDown(aspect.fieldTemplates, aspect);
                for (let row of aspect.rows) {
                    cascadeRefDataFieldsDown(row.fields, aspect);
                }
            }
        }
    }
}

function runRules(formState: FormState, aspectState: AspectState, fieldState?: FieldState) {
    const relevantRules = formState.rules.filter(r => r.conditionSections.includes(aspectState.template.id) ||
        (fieldState && r.conditionFields.includes(fieldState.template.id))
    );
    applyRules(formState, relevantRules).forEach(s => {
        if (AspectTypes.SINGLE_SECTION === s.type || AspectTypes.MULTI_SECTION === s.type) {
            if (!isVisible(s) || !isEditable(s)) {
                clearErrors(s);
            }
        } else if (!isVisible(s) || !isEditable(s) || !isMandatory(s)) {
            clearErrors(s);
        }
    });
}

function updateField(fieldState: FieldState, value: any, aspectState?: AspectState): void {
    if (FieldTypes.ADDRESS === fieldState.type && AspectTypes.SINGLE_SECTION === aspectState?.type) {
        for (let i = 1; i < aspectState.fields.length; i++) {
            const field = aspectState.fields[i];
            // Only TEXT and DROPDOWN_SINGLE field is supported now
            const parts = field.template.parameters.filter(p => p && "GEOCODE" === p.key);
            if (parts.length === 0) {
                continue;   // skip those without GEOCODE property
            }
            const responseParts = parts.map(p => p.value ? value[p.value] : "");
            if (FieldTypes.TEXT === field.type) {
                const str = responseParts.map(l => l && l.trim()).filter(l => l).join(" ");
                updateField(field, str);
            } else if (FieldTypes.DROPDOWN_SINGLE === field.type && responseParts.length === 1) {
                const refCodeName = responseParts[0].split(";");
                if (refCodeName.length === 2) {
                    updateField(field, {code: refCodeName[0], name: refCodeName[1]});
                }
            }
        }
    } else if (FieldTypes.LABEL !== fieldState.type) {
        fieldState.value = value;
    }
    fieldState.customErrors = [];
}

function validateForm(formState: FormState, action: PayloadAction<number | undefined>) {
    const validateUpToGroup = action.payload ? action.payload : formState.groups.length;
    formState.validatedGroups = validateUpToGroup;

    let aspectIds = formState.groups
        .slice(0, validateUpToGroup)
        .map(g => g.aspectIds)
        .flat();

    for (let aspect of formState.aspects) {
        if (!aspectIds.includes(aspect.id)) {
            continue;
        }
        if (aspect.type === AspectTypes.SINGLE_SECTION) {
            for (let field of aspect.fields) {
                validateField(aspect, field);
            }
        } else if (aspect.type === AspectTypes.MULTI_SECTION) {
            for (let row of aspect.rows) {
                for (let field of row.fields) {
                    validateField(aspect, field);
                }
            }
        }
    }
}

export function applyErrorsFromSubmissionErrors(formState: FormState, domainValidation: DomainValidation) {
    formState.customErrors = convertErrorStringsToIMessages(domainValidation.errors, true);
    domainValidation.sections?.forEach(e => {
        let aspect = formState.aspects.find(a => a.template.id === e.id);
        if (aspect) {
            aspect.customErrors = convertErrorStringsToIMessages(e.errors);
            if (aspect.type === AspectTypes.SINGLE_SECTION) {
                setFieldErrors(aspect.fields, e.fields);
            }
        }
    });
    domainValidation.multiSectionRows?.forEach(e => {
        let aspect = formState.aspects.find(a => a.template.id === e.id);
        if (aspect && aspect.type === AspectTypes.MULTI_SECTION) {
            let row = aspect.rows.find(row => row.key === e.rowId);
            if (row) {
                row.customErrors = convertErrorStringsToIMessages(e.errors);
                setFieldErrors(row.fields, e.fields);
            }
        }
    });
}

function applySubmissionErrorsAction(formState: FormState, action: PayloadAction<DomainValidation>) {
    const domainValidation: DomainValidation = action.payload;
    applyErrorsFromSubmissionErrors(formState, domainValidation);
}

function convertErrorStringsToIMessages(errorMessages: string[] | undefined, checkForWarnings: boolean = false): IMessage[] {
    if (errorMessages === undefined) {
        return [];
    }
    return errorMessages.map(errorMessage => ({
        text: errorMessage,
        type: checkForWarnings ? getAlertTypeForMessage(errorMessage) : AlertTypes.ERROR
    }));
}

function getAlertTypeForMessage(message: string): AlertTypes {
    if (message.startsWith("File upload in progress")) {
        return AlertTypes.INFO;
    }
    return AlertTypes.ERROR;
}

function setFieldErrors(fields: FieldState[], fieldErrors: DomainFieldValidation[] | undefined): void {
    if (fieldErrors) {
        fieldErrors.forEach(fieldError => {
            let field = fields.find(f => f.template.id === fieldError.id) as CommonFieldState;
            if (field) {
                field.customErrors = convertErrorStringsToIMessages(fieldError.errors);
                field.errors = {...field.errors, [ErrorType.CUSTOM_ERRORS]: true};
            }
        });
    }
}

function applyTriggerData(formState: FormState, action: PayloadAction<DomainData>) {
    applyDataToFormState(action.payload, formState);
}

function validateField(aspectState: AspectState, fieldState: FieldState): void {
    clearErrors(fieldState);
    if (!isVisible(aspectState) || !isEditable(aspectState) || !isVisible(fieldState) || !isEditable(fieldState)) {
        return;
    }
    if (isEmpty(fieldState) || (fieldState.type === FieldTypes.CHECK_BOX && !fieldState.value)) {
        if (isMandatory(fieldState)) {
            fieldState.errors.missing = true;
        }
    } else switch (fieldState.type) {
        case FieldTypes.TEXT:
            validateTextField(fieldState);
            validateRegEx(fieldState);
            break;
        case FieldTypes.NUMBER:
            validateNumberField(fieldState);
            validateRegEx(fieldState);
            break;
        case FieldTypes.DATE:
        case FieldTypes.DATE_TIME:
            validateDateField(fieldState);
    }
    fieldState.errors[ErrorType.CUSTOM_ERRORS] = fieldState.customErrors.length !== 0;
}

function validateRegEx(fieldState: TextFieldState | NumberFieldState): void {
    if (fieldState.regExValidation && !new RegExp("^" + fieldState.regExValidation.regEx + "$").test(fieldState.value!.toString())) {
        fieldState.errors.malformed = true;
    }
}

function validateTextField(fieldState: TextFieldState): void {
    if (fieldState.maxLength && fieldState.maxLength < fieldState.value.length) {
        fieldState.errors.overMax = true;
    }
}

function validateNumberField(fieldState: NumberFieldState): void {
    if ((fieldState.min || fieldState.min === 0) && fieldState.min > fieldState.value!) {
        fieldState.errors.belowMin = true;
    } else if ((fieldState.max || fieldState.max === 0) && fieldState.max < fieldState.value!) {
        fieldState.errors.overMax = true;
    }
}

function validateDateField(fieldState: DateFieldState): void {
    if (fieldState.value!.invalid) {
        fieldState.errors.malformed = true;
    }
}

function addMultiSectionRow(formState: FormState, action: PayloadAction<MultiSectionAspectState>) {
    let aspectState = formState.aspects.find(a => a.id === action.payload.id);
    if (aspectState && aspectState.type === AspectTypes.MULTI_SECTION) {
        aspectState.rows.push({
            key: uuid.v4().toUpperCase(),
            isNew: true,
            removed: false,
            fields: [...aspectState.fieldTemplates],
            activeRules: {
                hide: [],
                require: [],
                disable: []
            },
            customErrors: [],
            displayOrder: aspectState.rows.length ? aspectState.rows[aspectState.rows.length - 1].displayOrder + 1 : 0
        });
        aspectState.customErrors = [];
        runRules(formState, aspectState);
    }
    formState.changesPending = true;
}

function removeMultiSectionRow(formState: FormState, action: PayloadAction<RemoveRowActionPayload>) {
    let aspectState = formState.aspects.find(a => a.id === action.payload.aspect.id);
    if (aspectState && aspectState.type === AspectTypes.MULTI_SECTION) {
        let rowState = aspectState.rows.find(r => r.key === action.payload.row.key);
        if (rowState) {
            if (rowState.isNew) {
                aspectState.rows.splice(aspectState.rows.indexOf(rowState), 1);
            } else {
                rowState.removed = true;
            }
        }
        aspectState.customErrors = [];
        runRules(formState, aspectState);
    }
    formState.changesPending = true;
}

function loadDomainForm(formState: FormState, action: PayloadAction<FormState>) {
    formState.aspects = action.payload.aspects;
    formState.groups = action.payload.groups;
    formState.actions = action.payload.actions;
    formState.typeName = action.payload.typeName;
    formState.typeId = action.payload.typeId;
    formState.domainCode = action.payload.domainCode;
    formState.status = action.payload.status;
    formState.inInitialStatus = action.payload.inInitialStatus;
    formState.draftEnabled = action.payload.draftEnabled;
    formState.rules = action.payload.rules;
    formState.changesPending = false;
    formState.customErrors = action.payload.customErrors;
    formState.state = action.payload.state;
    applyRules(formState, formState.rules);
}

export function loadForm(typeId: string, portalConfig: PortalConfig) {
    return function loadForm(dispatch: Dispatch<any>) {
        dispatch(formSlice.actions.startLoad());
        loadDomain(typeId, portalConfig).then(formState => {
            dispatch(formSlice.actions.loadDomainForm(formState));
        });
    }
}

export function loadExistingForm(domainCode: string, portalConfig: PortalConfig, errors?: DomainValidation) {
    return function loadForm(dispatch: Dispatch<any>) {
        dispatch(formSlice.actions.startLoad());
        loadExistingDomain(domainCode, portalConfig).then(formState => {
            if (errors) {
                applyErrorsFromSubmissionErrors(formState, errors);
            }
            dispatch(formSlice.actions.loadDomainForm(formState));
        });
    }
}

export function validateRoute(redirectToGroup: (groupIndex: number) => void, groupIndex?: number) {
    return function validate(dispatch: Dispatch<any>, getState: () => FormState) {
        if (groupIndex === 0) {
            return;
        }
        dispatch(formSlice.actions.validateForm(groupIndex));
        let formState = getState();
        let groupInError = findFirstGroupInError(formState.groups, formState.aspects);
        if (groupInError === -1 || (groupIndex !== undefined && groupInError >= groupIndex)) {
            return;
        }
        redirectToGroup(groupInError);
    }
}

function findFirstGroupInError(groups: GroupState[], aspects: AspectState[]): number {
    const visibleGroups = groups.filter(g => atLeastOneAspectVisible(g.aspectIds, aspects));
    for (let i = 0; i < visibleGroups.length; i++) {
        if (groups[i].aspectIds
            .map(id => aspects.find(a => a.id === id))
            .filter(notUndefined)
            .some(hasError)) {
            return i;
        }
    }
    return -1;
}

export function submitForm(successCallback?: (response: UpdateDomain) => void,
                           failedCallback?: () => void,
                           finallyCallback?: () => void) {
    return function loadForm(dispatch: Dispatch<any>, getState: () => FormState) {
        dispatch(formSlice.actions.validateForm());
        let formState: FormState = getState();
        if (aspectsHaveErrors(formState.aspects)) {
            finallyCallback && finallyCallback();
            return;
        }
        dispatch(formSlice.actions.startLoad());
        let submit: (state: FormState) => Promise<UpdateDomain> = formState.domainCode ? updateDomain : submitDomain;
        submit(formState)
            .then((updatedDomain) => successCallback && successCallback(updatedDomain))
            .catch((error) => {
                if (error.isAxiosError) {
                    const axiosError: AxiosError = error;
                    if (axiosError.response?.data) {
                        if (typeof axiosError.response.data === "string") {
                            dispatch(formSlice.actions.applySubmissionErrorsAction({errors: [axiosError.response.data]}));
                        } else {
                            dispatch(formSlice.actions.applySubmissionErrorsAction(axiosError.response.data));
                        }
                    }
                }
                failedCallback && failedCallback();
            }).finally(() => {
            dispatch(formSlice.actions.endLoad());
            finallyCallback && finallyCallback();
        });
    }
}

export function loadNewFormValues(dispatch: Dispatch<any>, updateDomain: UpdateDomain, portalConfig: PortalConfig) {
    const formState = metaToFormState(updateDomain.meta as DomainMeta, portalConfig);
    applyDataToFormState(updateDomain.data as DomainData, formState);
    dispatch(formSlice.actions.loadDomainForm(formState));
}

export function performFieldUpdate(change: FieldChangeActionPayload) {
    return function updateLogic(dispatch: Dispatch<any>, getState: () => FormState) {
        dispatch(formSlice.actions.updateFieldValue(change));
        if (change.field.template.triggersRules) {
            const state = getState();
            triggerRules(state, change).then(update => {
                if (update.data) {
                    dispatch(formSlice.actions.applyTriggerData(update.data));
                }
            });
        }
    }
}

export function performAddRow(aspect: MultiSectionAspectState) {
    return function updateLogic(dispatch: Dispatch<any>, getState: () => FormState) {
        dispatch(formSlice.actions.addMultiSectionRow(aspect));
        if (aspect.template.triggersRules) {
            const state = getState();
            triggerSectionRules(state, aspect).then(update => {
                if (update.data) {
                    dispatch(formSlice.actions.applyTriggerData(update.data));
                }
            });
        }
    }
}

export function performRemoveRow(remove: RemoveRowActionPayload) {
    return function updateLogic(dispatch: Dispatch<any>, getState: () => FormState) {
        dispatch(formSlice.actions.removeMultiSectionRow(remove));
        if (remove.aspect.template.triggersRules) {
            const state = getState();
            triggerSectionRules(state, remove.aspect, remove.row.key).then(update => {
                if (update.data) {
                    dispatch(formSlice.actions.applyTriggerData(update.data));
                }
            });
        }
    }
}

export function performWorkflowAction(action: WorkflowActionState,
                                      successCallback?: (results: DomainWorkflowResult, code: string) => void,
                                      failedCallback?: (groupInError?: number, domainCode?: string) => void,
                                      finallyCallback?: () => void) {
    return function performAction(dispatch: Dispatch<any>, getState: () => FormState) {
        dispatch(formSlice.actions.validateForm());
        let formState: FormState = getState();
        let groupInError = findFirstGroupInError(formState.groups, formState.aspects);

        if (groupInError !== -1) {
            failedCallback && failedCallback(groupInError, formState.domainCode);
            finallyCallback && finallyCallback();
            return;
        }

        const code = getState().domainCode;
        if (!code) {
            return;
        }

        dispatch(formSlice.actions.startLoad());
        const executionRequest = executeWorkflowAction(code, action.id);
        executionRequest.then((domainWorkflowResult) => {
            const domainValidation = domainWorkflowResult.validation?.domainValidation;
            if (domainValidation && hasDomainValidationErrors(domainValidation)) {
                dispatch((formSlice.actions.applySubmissionErrorsAction(domainValidation)));
            } else {
                successCallback && successCallback(domainWorkflowResult, code);
            }
        }).catch(() => {
            failedCallback && failedCallback();
        }).finally(() => {
            dispatch(formSlice.actions.endLoad());
            finallyCallback && finallyCallback();
        });
    }
}

export function hasDomainValidationErrors(domainValidation: DomainValidation): boolean {
    return !!domainValidation.multiSectionRows?.length || !!domainValidation.sections?.length || !!domainValidation.errors?.length;
}