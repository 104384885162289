import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation} from 'react-router-dom';
import './App.css';
import {getUiConfig} from "../api/ui-services";
import {AppConfigContext, AppLoginContext, LoadingProvider, PortalConfig, UserProfile} from "../api/contexts";
import {ButtonTypes, ThemeProvider} from "odl-components";
import logo from "../assets/rw-main-logo.svg";
import {Box, Grid, Menu, MenuItem, PopoverOrigin} from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline';

import {SubmissionsPage} from "../features/SubmissionsPage";
import {APP_ROUTES} from "../api/app-routes";

import {configureStore} from "@reduxjs/toolkit";
import {Provider, useDispatch, useSelector} from "react-redux";
import {formSlice, initialState} from "../features/form/reducer";
import {SnackbarProvider} from "../components/SnackbarProvider";
import {bannerStyles, logoStyles, ODLMenuItemStyled, ODLMenuStyled, profileMenuStyles} from "./App.style";
import {createThemeOptions} from "./theme";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {FormSelector} from "../features/FormSelector/FormSelector";
import {Logo} from "../placeholder";
import {ReactComponent as RwSubLogo} from '../assets/rw-sub-logo.svg';
import {login, logout, REFRESH_MSG} from "../api/auth";
import axios from "axios";
import {refreshClient} from "../api/axios-client";
import {ODLButton} from "../components/ODLWrapper";
import {SearchPage} from "../features/Search/SearchPage";
import {FormState, StateEnum} from "../features/form/state";

function App() {
    useEffect(() => {
        if (window.sessionStorage.getItem("LoginAttempted") === "true") {
            window.sessionStorage.removeItem("LoginAttempted");
            if (window.opener) {
                window.opener.postMessage(REFRESH_MSG);
                window.close();
            }
        }
    }, []);
    if (window.location.pathname === "/login") {
        return <LoginComponent/>;
    }
    const store = configureStore({
        reducer: formSlice.reducer
    });
    return <Provider store={store}>
        <AppContainer/>
    </Provider>;
}

function AppContainer() {

    const [portalConfig, setPortalConfig] = useState<PortalConfig>();
    const [userProfile, setUserProfile] = useState<UserProfile>();

    useEffect(() => {
        if (!userProfile || userProfile.anonymousUser) {
            axios.defaults.headers.common["PublicUser"] = "true";
            refreshClient();
        } else {
            delete axios.defaults.headers.common["PublicUser"];
            refreshClient();
        }
    }, [userProfile]);

    useEffect(() => {
        getUiConfig().then(r => {
            setPortalConfig(r.portalConfig);
            setUserProfile(r.userProfile);
        });
    }, []);

    if (!portalConfig || !userProfile) {
        return null;
    }

    const themeOptions = createThemeOptions(portalConfig);

    const userContext = {
        profile: userProfile,
        login: () => login(() => getUiConfig().then(r => setUserProfile(r.userProfile)))
    };

    return <Router>

        <AppConfigContext.Provider value={portalConfig}>
            <AppLoginContext.Provider value={userContext}>
                <ThemeProvider theme={themeOptions}>
                    <CssBaseline/>
                    <SnackbarProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <LoadingProvider>
                                <AppContent/>
                            </LoadingProvider>
                        </MuiPickersUtilsProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </AppLoginContext.Provider>
        </AppConfigContext.Provider>

    </Router>;
}

function AppContent() {
    const profile: UserProfile = useContext(AppLoginContext).profile;
    const config: PortalConfig = useContext(AppConfigContext);
    const location = useLocation();
    const dispatch = useDispatch();
    const authenticatedUser: boolean = !profile.anonymousUser;
    const styles = bannerStyles();
    const logoStyle = logoStyles();
    const state = useSelector((state: FormState) => state.state);

    useEffect(() => {
        if (state === StateEnum.INITIALIZED && !(location.pathname.startsWith(APP_ROUTES.submissionsNew) || location.pathname.startsWith(APP_ROUTES.submissionsEdit))) {
            dispatch(formSlice.actions.loadDomainForm(initialState));
        }
    }, [location, dispatch, state]);

    return <Grid container direction="column" wrap="nowrap">
        <Grid container alignItems="center" className={styles.header}>
            <ClickableLogo id="header-logo" {...config.headerLogo}/>
        </Grid>
        <Grid container item xs className={styles.content}>
            <Grid item xs>
                <Switch>
                    <Route path={APP_ROUTES.submissions} component={SubmissionsPage}/>
                    <Route path={APP_ROUTES.search} component={SearchPage}/>
                    {!authenticatedUser && <Route path={APP_ROUTES.submissionsNew} component={FormSelector}/>}
                    <Route path="/" render={() => (
                        authenticatedUser ? <Redirect to={APP_ROUTES.submissionsDashboard}/> :
                            <Redirect to={APP_ROUTES.submissionsNew}/>
                    )}/>
                </Switch>
            </Grid>
        </Grid>
        <Grid container alignItems="center" justify="space-between" className={styles.footer}>
            {config.footerLogo?.path && <ClickableLogo id="footer-logo"{...config.footerLogo}/>}
            <a href="https://www.objective.com.au/products/objective-regworks" className={logoStyle.anchor}>
                <RwSubLogo id="rw-sub-logo"/>
            </a>
        </Grid>
    </Grid>
}

function LoginComponent() {
    useEffect(() => {
        getUiConfig().then(() => {
        });
    }, []);
    return <span>Logging in</span>;
}

function ClickableLogo(props: Logo & {id: string}) {
    const styles = logoStyles();
    let src = logo;
    if (props.path) {
        src = window.location.origin + "/public/fileDownload?path=" + props.path;
    }
    const replaceImageWithDefault = (e: React.ChangeEvent<HTMLImageElement>) => {
        e.target.src = logo;
        e.target.onerror = null;
    }
    const img = <img id={props.id} className={styles.image} src={src} alt={props.text} title={props.text}
                     onError={replaceImageWithDefault}/>;
    return props.link ? <a className={styles.anchor} href={props.link}>{img}</a> : img;
}

export function ProfileMenu() {
    const styles = profileMenuStyles();
    const odlMenuStyled = ODLMenuStyled();
    const odlMenuItemStyled = ODLMenuItemStyled();

    const [anchorEl, setAnchorEl] = React.useState<EventTarget & Element | null>(null);
    const anchorOrigin: PopoverOrigin = {vertical: "bottom", horizontal: "center"};
    const profile: UserProfile = useContext(AppLoginContext).profile;
    const changesPending = useSelector((formState: FormState) => formState.changesPending);
    const routerValidationMessage: string = "Changes you made may not be saved. Do you wish to continue?";

    const handleClick = (event: React.SyntheticEvent) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const checkForChanges = () => {
        const path: string = window.location.pathname;
        if ((path.startsWith(APP_ROUTES.submissionsEdit) ||
            (path.startsWith(APP_ROUTES.submissionsNew) && path !== APP_ROUTES.submissionsNew)) && changesPending) {
            const response = window.confirm(routerValidationMessage);
            response ? onLogOut() : handleClose();
        } else {
            onLogOut();
        }
    }
    const onLogOut = () => {
        handleClose();
        logout();
    }

    return <Box marginRight="auto">
        <ODLButton onClickHandler={event => handleClick(event)} className={styles.userProfileButton}
                text={profile.fullName ? profile.fullName : profile.username}
                type={ButtonTypes.TEXT} icon={<span className="icon icon-account-circle"/>}
                endIcon={<span className="icon icon-menu-down"/>}/>
        <Menu id="user-profile-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}
              getContentAnchorEl={null} anchorOrigin={anchorOrigin}
              classes={{list: odlMenuStyled.list, paper: odlMenuStyled.paper}}>
            <MenuItem onClick={checkForChanges} classes={{root: odlMenuItemStyled.root, selected: odlMenuItemStyled.selected}}
                      disableGutters={true}>
                <span className="icon icon-logout-variant"/>
                <div className={styles.menuItemContent}>Logout</div>
            </MenuItem>
        </Menu>
    </Box>
}

export default App;
