import {
    Box,
    Link,
    Table,
    TableCell,
    TableBody,
    TableRow,
    Typography,
    Menu, MenuItem, TablePagination, TablePaginationProps, TableContainer,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {ButtonTypes, Loader, ODL_ICONS} from 'odl-components';
import {useHistory} from 'react-router-dom';

import { DashboardWidgetState } from './DashboardPage.reducer';
import {APP_ROUTES} from "../../api/app-routes";
import clsx from "clsx";
import {
    DomainWorkflowList,
    executeWorkflowAction,
    getWorkflowActions,
    workflowFailureMessage,
    workflowSuccessMessage
} from "../../api/workflow-service";
import {processRedirectActions} from "../../api/redirect-service";
import {useSnackbar} from "../../components/SnackbarProvider";
import {dashboardWidgetStyle, SecondaryActions, PrimaryActions, workflowActionStyles} from "./DashboardWidget.style";
import {useLoadingContext} from "../../api/contexts";
import {getCancelToken} from "../../api/axios-client";
import {PaginationItem} from '@material-ui/lab';
import {SearchResultRowState} from "../Search/Search";
import {hasDomainValidationErrors} from "../form/reducer";


const NON_BREAKING_SPACE = "\u00A0";

type DashboardWidgetElementProps = {
    widget: DashboardWidgetState;
    triggerRefresh?: () => void;
    triggerUpdate: (searchId: string, page: number, pageSize: number) => void;
}

/**
 * A header and a results table
 */
export function DashboardWidgetElement(props: DashboardWidgetElementProps) {
    const {widget, triggerRefresh, triggerUpdate} = props;
    const styleClasses = dashboardWidgetStyle();

    let rows = null;
    if (!widget.results) {
        rows = <TableRow key="loader">
            <TableCell className={styleClasses.emptyCell}>
                <Loader a11yId="widget-loader" isLoading={true} size={50} />
            </TableCell>
        </TableRow>;
    } else if (widget.results.searchResultRows.length === 0) {
        rows = <TableRow key="no-results">
            <TableCell className={styleClasses.emptyCell}><span className="icon icon-information-outline" />&nbsp;No Results</TableCell>
        </TableRow>;
    } else {
        rows = widget.results.searchResultRows.map((r, i) =>
            <DashboardWidgetRow result={r} index={i} key={r.id} triggerRefresh={triggerRefresh}/>
        );
    }
    const count = widget.results ? widget.results.count : 0;
    const controlProps: TablePaginationProps<"div", {component: "div"}> = {
        component: "div",
        className: styleClasses.row,
        ActionsComponent: CustomActions,
        SelectProps: {native: true},
        rowsPerPageOptions: [5, 10, 25],
        count,
        page: widget.page,
        rowsPerPage: widget.pageSize,
        onChangePage: (event, newPage) => triggerUpdate(widget.searchId, newPage, widget.pageSize),
        onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) =>
            triggerUpdate(widget.searchId, 0, parseInt(event.target.value))
    };

    return <div>
        {DashboardWidgetHeader(widget)}
        <div className={styleClasses.widgetContainer}>
            <TableContainer>
                <Table className={styleClasses.table}>
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
            {count > 0 && <TablePagination {...controlProps}/>}
        </div>
    </div>
}

function CustomActions(props: {count: number, page: number, rowsPerPage: number, onChangePage: any}) {
    const {count, page, rowsPerPage, onChangePage} = props;
    const MAX_RECORD = 100000;
    const lastPage = Math.ceil(Math.min(count, MAX_RECORD) / rowsPerPage) - 1;
    const disableGoingBack = page === 0;
    const disableGoingNext = page >= lastPage;
    return <>
        <PaginationItem type="first" onClick={() => onChangePage(null, 0)} disabled={disableGoingBack}/>
        <PaginationItem type="previous" onClick={() => onChangePage(null, page - 1)} disabled={disableGoingBack}/>
        <PaginationItem type="next" onClick={() => onChangePage(null, page + 1)} disabled={disableGoingNext}/>
        <PaginationItem type="last" onClick={() => onChangePage(null, lastPage)} disabled={disableGoingNext}/>
    </>;
}

function DashboardWidgetRow(props: { result: SearchResultRowState, index: number, triggerRefresh?: () => void}) {
    const {result} = props;
    const styleClasses = dashboardWidgetStyle();
    const history = useHistory();
    const isDomain = result.type === "activity" || result.type === "entity";

    // Replace missing values with a non-breaking space (\u00A0)
    const columnVals = result.columns.map(c => c.value).map(c => c === null || c.length === 0 ? NON_BREAKING_SPACE : c);
    if (columnVals.length % 2 === 1) {
        columnVals.push(NON_BREAKING_SPACE);
    }

    // Reducer to group into pairs
    // TODO likley to change in future updates, perhaps hard-coded columns
    const cells: any[][] = columnVals.reduce((result: any[], value, index, array) => {
        if (index % 2 === 0)
            result.push(array.slice(index, index + 2));
        return result;
    }, []);

    // TODO hardcoded icon for now
    const typeIcon = <span className={clsx("icon", ODL_ICONS.EMAIL_OUTLINE)}/>;

    const onRowClick = (event: React.SyntheticEvent) => {
        if (isDomain) {
            history.push(`${APP_ROUTES.submissionsEdit}/${result.code}`);
        }
    }

    const rowClass = clsx({
        [styleClasses.row]: true,
        [styleClasses.clickable]: isDomain
    });
    return <TableRow className={rowClass} onClick={event => onRowClick(event)}>
        <TableCell>
            {typeIcon}
        </TableCell>
        {cells.map((c, i) => <DashboardWidgetCell topText={c[0]} bottomText={c[1]} isLink={i === 1 && isDomain} key={"cell-" + i} />)}
        <WorkflowActionComponent isDomain={isDomain} result={result} index={props.index} triggerRefresh={props.triggerRefresh}/>
    </TableRow>
}

function preventPropagation(event: React.SyntheticEvent) {
    event.stopPropagation();
    event.preventDefault();
}

function WorkflowActionComponent(props: {isDomain: boolean, index: number, result: SearchResultRowState, triggerRefresh?: () => void}) {
    const {isDomain, index, triggerRefresh, result} = props;
    const styleClasses = dashboardWidgetStyle();
    const workflowActionClasses = workflowActionStyles();
    const history = useHistory();
    const snackBar = useSnackbar();
    const loadingContext = useLoadingContext();

    const [actions, setActions] = useState<DomainWorkflowList>({count: 0, pageOffset: 0, pageSize: 0, results:[]});
    const [anchorEl, setAnchorEl] = React.useState<EventTarget & Element | null>(null);
    const handleClick = (event: React.SyntheticEvent) => {
        setAnchorEl(event.currentTarget);
        preventPropagation(event);
    };
    const handleClose = () => {
        setAnchorEl(null);
    }

    useEffect(() => {
        let cancelToken = getCancelToken();
        let mounted = true;
        if (result.code && isDomain) {
            getWorkflowActions(result.code, cancelToken).then(results => {
                if (mounted) {
                    setActions(results);
                }
            }).catch(error => {});
        }
        return () => {
            cancelToken.cancel("Page content changed");
            mounted = false;
        };
    }, [result, isDomain]);

    const actionIcon = <span className={clsx("icon", ODL_ICONS.DOTS_HORIZONTAL)} title="actions" />;
    const executeAction = (event: React.SyntheticEvent, id: string) => {
        event.stopPropagation();
        event.preventDefault();
        handleClose();
        loadingContext.setLoading(true);
        executeWorkflowAction(result.code, id, true).then((domainWorkflowResult) => {
            const domainValidation = domainWorkflowResult.validation?.domainValidation;
            if (domainValidation && hasDomainValidationErrors(domainValidation)) {
                history.push(`${APP_ROUTES.submissionsEdit}/${result.code}`, {errors: domainValidation});
                loadingContext.setLoading(false);
            } else {
                snackBar.addMessage(workflowSuccessMessage);
                if (!processRedirectActions(domainWorkflowResult.clientActions, history, snackBar)) {
                    setTimeout(() => {
                        triggerRefresh && triggerRefresh();
                    }, 1000);
                } else {
                    loadingContext.setLoading(false);
                }
            }
        }).catch(() => {
            snackBar.addMessage(workflowFailureMessage);
            loadingContext.setLoading(false);
        });
    };
    if (!actions.count) {
        return <TableCell/>;
    }

    const primaryButton = <PrimaryActions text={actions.results[0].name} type={ButtonTypes.PRIMARY} useWhiteLabel
                                          onClickHandler={(event: React.SyntheticEvent) => executeAction(event, actions.results[0].id)}/>
    if (actions.count === 1) {
        return <TableCell className={workflowActionClasses.actionCell}>{primaryButton}</TableCell>;
    }

    let menu;
    if (anchorEl) {
        menu =
            <Menu id="workflow-options-menu" className={workflowActionClasses.menu} anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}
                  getContentAnchorEl={null} anchorOrigin={{vertical: "bottom", horizontal: "center"}} onClick={preventPropagation}>
                <Box className={workflowActionClasses.heading}>{result.code}</Box>
                {actions.results.slice(1).map(a =>
                    <MenuItem key={a.id} className={workflowActionClasses.item} onClick={(event) => executeAction(event, a.id)} id={"workflow-other-option-" + a.id}>
                        <span>{a.name}</span>
                    </MenuItem>
                )}
            </Menu>;
    }

    return <TableCell className={workflowActionClasses.actionCell}>
        {primaryButton}
        <SecondaryActions a11yId={"row-" + index + "-button"} text="" icon={actionIcon} className={styleClasses.actionIcon}
                          onClickHandler={handleClick} />
        {menu}
    </TableCell>
}

function DashboardWidgetCell(props: { topText: string, bottomText: string, isLink?: boolean }) {
    const styleClasses = dashboardWidgetStyle();

    let topElement = null;
    if (props.isLink) {
        topElement = <b><Link>{props.topText}</Link></b>;
    } else {
        topElement = <b>{props.topText}</b>;
    }

    return <TableCell className={styleClasses.cell}>
        {topElement}
        <div>{props.bottomText}</div>
    </TableCell>
}

/**
 *  A Title, optional counter, and table controls
 */
function DashboardWidgetHeader(widget: DashboardWidgetState) {
    return <Box>
        <Typography variant="h6">{widget.title}</Typography>
    </Box>
}